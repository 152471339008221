/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../Redux/hooks";
import { LanguageLine } from "./Model/LanguageLine";

var cachedEntries = new Map()
export interface ILanguageContext {
    updateLanguage: (lang: string) => Promise<void>, 
    getTranslatedText: (key: string, values?: Array<string>) => string
}

export const LanguageContext = React.createContext<ILanguageContext>(undefined!);

export const LanguageConsumer = LanguageContext.Consumer;



interface LanguageProviderProps {
    children: any
}

export  const getTranslation = (key: string, values?: Array<string>) : string => {
    let returnValue = ""
    if(cachedEntries.has(key))
    {
        returnValue = cachedEntries.get(key);
        
        if(values !== undefined){

            values.forEach((value,index) => {
                returnValue = returnValue.replace('{' + index + '}', value)
            });
        }   

        return returnValue;
    }
    return key;
} 
 

export const LanguageProvider = (props: LanguageProviderProps) => {

    const language = useAppSelector(store => store.settings.language);
    const languageFile = useAppSelector(store => store.languageFile.languageFileContent);
    
    const [value, setValue] = useState(0); // Zaubertrick

    useEffect(() => {
        setLanguage("de");
        setValue(value => value + 1);
    }, [language, languageFile])

    const getTranslatedText = (element: LanguageLine, isoCode: string) : string => {    
        var returnValue = element.en;
    
        if(isoCode === "de" && element.de !== "") {
            returnValue = element.de;
            return returnValue;
        }
        
        return returnValue;
    }


    const setLanguage = async(isoCode: string) => {
        if(languageFile){
            for(let value of languageFile){
                var element = value;

                var translatedText = getTranslatedText(element, isoCode);
                cachedEntries.set(element.key,  translatedText);
            }
        }   
    }

    setLanguage(language)
    
    return ( 
    <LanguageContext.Provider value={{ updateLanguage: setLanguage, getTranslatedText: getTranslation}}>
        {props.children}
    </LanguageContext.Provider> 

    );

}