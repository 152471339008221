import { Grow, Grid, Container } from "@mui/material";
import React from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { Helmet } from "react-helmet";
import { PrivacyLanguage } from "./Languages/PrivacyLanguage";
import { useAppSelector } from "../../Infrastructure/Redux/hooks";

export const Privacy = () => {
    const language = useAppSelector(store => store.settings.language);

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                    <Helmet>
                    <title>Datenschutz</title>
                    <meta name="description" content="Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf. (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO)."/>
                    </Helmet>
                    <React.Fragment>
                        <Grow in={true} appear={true}>
                            <Grid container direction={'row'} spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <PrivacyLanguage lang={language} />
                                </Grid>
                            </Grid>
                        </Grow>
                    </React.Fragment>
                </Container>
            }

        </LanguageConsumer>
    );
}