import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LanguageConsumer } from '../../../Infrastructure/Internationalisation/TranslationService';
import { useLocation, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const PageBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState<any>();

    useEffect(() => {
        switch(location.pathname){
            case "/":
                setTabIndex(0);
                break;
            case "/about":
                setTabIndex(1);
                break;
            case "/projects":
                setTabIndex(2);
                break;
            default:
                setTabIndex(3);
                break;
        }
    }, [location])

    const a11yProps = (index: number) => {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        switch(newValue){
            case 0:
                navigate("/");
                break;
            case 1: 
                navigate("/about");
                break;
            case 2:
                navigate("/projects");
                break;
            default: 
                break;
        }
    };

    return (<LanguageConsumer>

        {({ getTranslatedText }) => <React.Fragment>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '48px', borderRadius: 10, backgroundColor: 'rgba(232, 232, 232, 0.6)' }}>
                <Tabs disableRipple TabIndicatorProps={{ style: { background: '#444', height: 4, borderRadius: 2, width: 30, display: tabIndex === 3? 'none' : 'flex', justifyContent: 'center', alignItems: 'center', alignSelf: 'center', marginBottom: 5, marginLeft: tabIndex === 0? 38 : tabIndex === 1? 35 : 32} }} textColor="inherit"  value={tabIndex} onChange={handleChange} aria-label="basic tabs example">
                    <Tab disableRipple label="Startseite" {...a11yProps(0)} />
                    <Tab disableRipple label="Über uns" {...a11yProps(1)} />
                    <Tab disableRipple label="Projekte" {...a11yProps(2)} />
                    <Tab disableRipple label="" {...a11yProps(3)} sx={{display: 'none'}}/>
                </Tabs>
            </Box>        
        </React.Fragment>}
    </LanguageConsumer>

    );
}

export default PageBar;