import { Container, Grid, IconButton, Divider, Typography, Box } from "@mui/material";
import './Style/Footer.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import {FaTiktok} from "react-icons/fa";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Footer = () => {

    return(
        <Box
        component="footer"
        sx={{
          py: 1,
          px: 2,
          mt: 'auto'
        }}
      >
        <LanguageConsumer>

            {({ getTranslatedText }) =>
            <Fade>
                <footer className='footer-light'>
                    <Container maxWidth="xl" sx={{ mt: 0, mb: 1, maxHeight: '62px' }}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} sx={{mt: 2, display: 'flex', flexDirection: {xs: 'column', md: 'row'}, justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography variant="subtitle2" sx={{color: '#444', alignItems: 'center', display: 'flex'}}><span style={{paddingTop: 2, marginRight: 1}}>©</span>{`${new Date().getFullYear()} Botnatic - All rights reserved.`}</Typography>
                                <Box component={'div'} sx={{display: 'flex', gap: 1, alignItems: 'center', flexDirection: {xs: 'column', md: 'row'}}}>
                                <Box component={'div'} sx={{display: 'flex', gap: 1, mt: {xs: 1, md: 0}}}>
                                    <NavLink to="/imprint"><Typography variant="subtitle2" sx={{color: '#444'}}>Impressum</Typography></NavLink>
                                    <Divider orientation="vertical" sx={{height: 20, mx: 1}}/>
                                    <NavLink to="/privacy"><Typography variant="subtitle2" sx={{color: '#444'}}>Datenschutz</Typography></NavLink>
                                </Box>
                                <Divider orientation="vertical" sx={{height: 20, ml: 1, display: {xs: 'none', md: 'flex'}}}/>
                                <Box component={'div'} sx={{display: 'flex', gap: 1, mb: {xs: 1, md: 0}}}>
                                    <IconButton href="https://instagram.com/botnatic" target="_blank">
                                        <InstagramIcon/>
                                    </IconButton>
                                    <IconButton href="https://facebook.com/botnatic_de" target="_blank">
                                        <FacebookIcon/>
                                    </IconButton>
                                    <IconButton href="https://twitter.com/botnatic_de" target="_blank">
                                        <TwitterIcon/>
                                    </IconButton>
                                    <IconButton href="https://tiktok.com/@botnatic_de" target="_blank" style={{width: '40px'}}>
                                        <FaTiktok size={18}/>
                                    </IconButton>
                                    <IconButton href="https://youtube.com/@botnatic" target="_blank">
                                        <YouTubeIcon/>
                                    </IconButton>
                                </Box>
                                
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </footer>
                </Fade>}
        </LanguageConsumer>
        </Box>
    );
}

export default Footer;