import React from "react"

export const PrivacyLanguage = ({ lang }) => {

    if (lang === "de") {
        return (<React.Fragment>
            <div style={{ margin: 3 + 'em' }} >

                <h2>Datenschutzerklärung</h2>

                <p>
                Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf. (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).

                </p>

                <h3>
                Verantwortlicher
                </h3>

                <p>
                    Botnatic - Inh. Patrick Gerstacker<br/>
                    Schlehenweg 4<br/>
                    92256 Hahnbach<br/><br/>

                    Tel: +49 (0) 170 6572044<br/>
                    Web: botnatic.com<br/>
                    E-Mail: info@botnatic.com<br/>
                </p>

                <h3>
                    Arten der verarbeiteten Daten:
                </h3>

                <p>
                - Bestandsdaten (z.B., Namen, Adressen).<br/>
                - Kontaktdaten (z.B., E-Mail, Telefonnummern).<br/>
                - Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).<br/>
                - Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).<br/>
                - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
                </p>

                <h3>
                    Verwendete Begrifflichkeiten
                </h3>

                <p>
                „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.

                    <br /><br />

                    Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.
                    <br/><br/>
                    Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
                </p>

                <h3>
                   Maßgebliche Rechtsgrundlagen
                </h3>

                <p>
                Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage..
                </p>

                <h3>
                    Sicherheitsmaßnahmen
                </h3>

                <p>
                Wir bitten Sie sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird.
                </p>

                <h3>
                    Zusammenarbeit mit Auftragverarbeitern und Dritten
                </h3>

                <p>
                Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).

                    <br /><br />
                    Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.

                </p>

                <h3>
                    Übermittlungen in Drittländer
                </h3>

                <p>
                Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
                </p>

                <h3>
                    Rechte der betroffenen Personen
                </h3>

                <p>
                Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.
                <br/><br/>
                Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
                <br/><br/>
                Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der Daten zu verlangen.
                <br/><br/>
                Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
                <br/><br/>
                Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
                </p>

                <h3>
                    Widerrufsrecht
                </h3>

                <p>
                Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen
                </p>

                <h3>
                    Widerspruchsrecht
                </h3>
                <p>
                Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
                </p>
                
                <h3>
                    Cookies und Widerspruchsrecht bei Direktwerbung
                </h3>  

                <p>
                Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Staus gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).
                <br/><br/>
                Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung auf.
                <br/><br/>
                Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.
                <br/><br/>
                Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite <a href="http://www.aboutads.info/choices/" style={{ color:"#0645AD"}}>http://www.aboutads.info/choices/</a> oder die EU-Seite <a href="http://www.youronlinechoices.com/" style={{ color:"#0645AD"}}>http://www.youronlinechoices.com/</a> erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
                </p>

                <h3>
                    Löschung von Daten
                </h3>

                <p>
                Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
                <br/><br/>
                Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe).
                <br/><br/>
                Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen im Zusammenhang mit elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und Fernsehleistungen, die an Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruch genommen wird.
                </p>

                <h3>
                    Hosting
                </h3>
                <p>
                Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.
                <br/><br/>
                Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).
                </p>

                <h3>
                    Erhebung von Zugriffsdaten und Logfiles
                </h3>
                <p>
                Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
                <br/><br/>
                Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
                </p>

                <h3>
                    Agenturdienstleistungen
                </h3>
                <p>
                Wir verarbeiten die Daten unserer Kunden im Rahmen unserer vertraglichen Leistungen zu denen konzeptionelle und strategische Beratung, Kampagnenplanung, Software- und Designentwicklung/-beratung oder Pflege, Umsetzung von Kampagnen und Prozessen/ Handling, Serveradministration, Datenanalyse/ Beratungsleistungen und Schulungsleistungen gehören.
                <br/><br/>
                Hierbei verarbeiten wir Bestandsdaten (z.B., Kundenstammdaten, wie Namen oder Adressen), Kontaktdaten (z.B., E-Mail, Telefonnummern), Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos), Vertragsdaten (z.B., Vertragsgegenstand, Laufzeit), Zahlungsdaten (z.B., Bankverbindung, Zahlungshistorie), Nutzungs- und Metadaten (z.B. im Rahmen der Auswertung und Erfolgsmessung von Marketingmaßnahmen). Besondere Kategorien personenbezogener Daten verarbeiten wir grundsätzlich nicht, außer wenn diese Bestandteile einer beauftragten Verarbeitung sind. Zu den Betroffenen gehören unsere Kunden, Interessenten sowie deren Kunden, Nutzer, Websitebesucher oder Mitarbeiter sowie Dritte. Der Zweck der Verarbeitung besteht in der Erbringung von Vertragsleistungen, Abrechnung und unserem Kundenservice. Die Rechtsgrundlagen der Verarbeitung ergeben sich aus Art. 6 Abs. 1 lit. b DSGVO (vertragliche Leistungen), Art. 6 Abs. 1 lit. f DSGVO (Analyse, Statistik, Optimierung, Sicherheitsmaßnahmen). Wir verarbeiten Daten, die zur Begründung und Erfüllung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe hin. Eine Offenlegung an Externe erfolgt nur, wenn sie im Rahmen eines Auftrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags überlassenen Daten handeln wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben einer Auftragsverarbeitung gem. Art. 28 DSGVO und verarbeiten die Daten zu keinen anderen, als den auftragsgemäßen Zwecken.
                <br/><br/>
                Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten. die Erforderlichkeit der Aufbewahrung der Daten wird alle drei Jahre überprüft; im Fall der gesetzlichen Archivierungspflichten erfolgt die Löschung nach deren Ablauf (6 J, gem. § 257 Abs. 1 HGB, 10 J, gem. § 147 Abs. 1 AO). Im Fall von Daten, die uns gegenüber im Rahmen eines Auftrags durch den Auftraggeber offengelegt wurden, löschen wir die Daten entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
                </p>

                <h3>
                    Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
                </h3>
                <p>
                Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden, Interessenten, Geschäftspartner und Websitebesucher betroffen. Der Zweck und unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, Büroorganisation, Archivierung von Daten, also Aufgaben die der Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und Erbringung unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf vertragliche Leistungen und die vertragliche Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten genannten Angaben.
                <br/><br/>
                Wir offenbaren oder übermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und Zahlungsdienstleister.
                <br/><br/>
                Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen Geschäftspartnern, z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich dauerhaft.
                </p>

                <h3>
                    Betriebswirtschaftliche Analysen und Marktforschung
                </h3>
                <p>
                Um unser Geschäft wirtschaftlich betreiben, Markttendenzen, Kunden- und Nutzerwünsche erkennen zu können, analysieren wir die uns vorliegenden Daten zu Geschäftsvorgängen, Verträgen, Anfragen, etc. Wir verarbeiten dabei Bestandsdaten, Kommunikationsdaten, Vertragsdaten, Zahlungsdaten, Nutzungsdaten, Metadaten auf Grundlage des Art. 6 Abs. 1 lit. f. DSGVO, wobei zu den betroffenen Personen Kunden, Interessenten, Geschäftspartner, Besucher und Nutzer des Onlineangebotes gehören.
                <br/><br/>
                Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen, des Marketings und der Marktforschung. Dabei können wir die Profile der registrierten Nutzer mit Angaben z.B. zu deren Kaufvorgängen berücksichtigen. Die Analysen dienen uns zur Steigerung der Nutzerfreundlichkeit, der Optimierung unseres Angebotes und der Betriebswirtschaftlichkeit. Die Analysen dienen alleine uns und werden nicht extern offenbart, sofern es sich nicht um anonyme Analysen mit zusammengefassten Werten handelt.
                <br/><br/>
                Sofern diese Analysen oder Profile personenbezogen sind, werden sie mit Kündigung der Nutzer gelöscht oder anonymisiert, sonst nach zwei Jahren ab Vertragsschluss. Im Übrigen werden die gesamtbetriebswirtschaftlichen Analysen und allgemeine Tendenzbestimmungen nach Möglichkeit anonym erstellt.
                </p>

                <h3>
                    Datenschutzhinweise im Bewerbungsverfahren
                </h3>
                <p>
                Wir verarbeiten die Bewerberdaten nur zum Zweck und im Rahmen des Bewerbungsverfahrens im Einklang mit den gesetzlichen Vorgaben. Die Verarbeitung der Bewerberdaten erfolgt zur Erfüllung unserer (vor)vertraglichen Verpflichtungen im Rahmen des Bewerbungsverfahrens im Sinne des Art. 6 Abs. 1 lit. b. DSGVO Art. 6 Abs. 1 lit. f. DSGVO sofern die Datenverarbeitung z.B. im Rahmen von rechtlichen Verfahren für uns erforderlich wird (in Deutschland gilt zusätzlich § 26 BDSG).
                <br/><br/> 
                Das Bewerbungsverfahren setzt voraus, dass Bewerber uns die Bewerberdaten mitteilen. Die notwendigen Bewerberdaten sind, sofern wir ein Onlineformular anbieten gekennzeichnet, ergeben sich sonst aus den Stellenbeschreibungen und grundsätzlich gehören dazu die Angaben zur Person, Post- und Kontaktadressen und die zur Bewerbung gehörenden Unterlagen, wie Anschreiben, Lebenslauf und die Zeugnisse. Daneben können uns Bewerber freiwillig zusätzliche Informationen mitteilen.
                <br/><br/>
                Mit der Übermittlung der Bewerbung an uns, erklären sich die Bewerber mit der Verarbeitung ihrer Daten zu Zwecken des Bewerbungsverfahrens entsprechend der in dieser Datenschutzerklärung dargelegten Art und Umfang einverstanden.
                <br/><br/>
                Soweit im Rahmen des Bewerbungsverfahrens freiwillig besondere Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO mitgeteilt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2 lit. b DSGVO (z.B. Gesundheitsdaten, wie z.B. Schwerbehinderteneigenschaft oder ethnische Herkunft). Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO bei Bewerbern angefragt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2 lit. a DSGVO (z.B. Gesundheitsdaten, wenn diese für die Berufsausübung erforderlich sind).
                <br/><br/>
                Sofern zur Verfügung gestellt, können uns Bewerber ihre Bewerbungen mittels eines Onlineformulars auf unserer Website übermitteln. Die Daten werden entsprechend dem Stand der Technik verschlüsselt an uns übertragen.
                Ferner können Bewerber uns ihre Bewerbungen via E-Mail übermitteln. Hierbei bitten wir jedoch zu beachten, dass E-Mails grundsätzlich nicht verschlüsselt versendet werden und die Bewerber selbst für die Verschlüsselung sorgen müssen. Wir können daher für den Übertragungsweg der Bewerbung zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen und empfehlen daher eher ein Online-Formular oder den postalischen Versand zu nutzen. Denn statt der Bewerbung über das Online-Formular und E-Mail, steht den Bewerbern weiterhin die Möglichkeit zur Verfügung, uns die Bewerbung auf dem Postweg zuzusenden.
                <br/><br/>
                Die von den Bewerbern zur Verfügung gestellten Daten, können im Fall einer erfolgreichen Bewerbung für die Zwecke des Beschäftigungsverhältnisses von uns weiterverarbeitet werden. Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht erfolgreich ist, werden die Daten der Bewerber gelöscht. Die Daten der Bewerber werden ebenfalls gelöscht, wenn eine Bewerbung zurückgezogen wird, wozu die Bewerber jederzeit berechtigt sind.
                <br/><br/>
                Die Löschung erfolgt, vorbehaltlich eines berechtigten Widerrufs der Bewerber, nach dem Ablauf eines Zeitraums von sechs Monaten, damit wir etwaige Anschlussfragen zu der Bewerbung beantworten und unseren Nachweispflichten aus dem Gleichbehandlungsgesetz genügen können. Rechnungen über etwaige Reisekostenerstattung werden entsprechend den steuerrechtlichen Vorgaben archiviert.
                </p>

                <h3>
                    Kontaktaufnahme
                </h3>
                <p>
                Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem Customer-Relationship-Management System ("CRM System") oder vergleichbarer Anfragenorganisation gespeichert werden.
                <br/><br/>
                Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.
                </p>

                <h3>Google Analytics</h3>
                <p>
                Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics, einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.
                <br/><br/>
                Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" style={{ color:"#0645AD"}}>https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>).
                <br/><br/>
                Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.
                <br/><br/>
                Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.
                <br/><br/>
                Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" style={{ color:"#0645AD"}}>http://tools.google.com/dlpage/gaoptout?hl=de</a>.
                <br/><br/>
                Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren Sie in der Datenschutzerklärung von Google (<a href="https://policies.google.com/technologies/ads" style={{ color:"#0645AD"}}>https://policies.google.com/technologies/ads</a>) sowie in den Einstellungen für die Darstellung von Werbeeinblendungen durch Google (<a href="https://adssettings.google.com/authenticated" style={{ color:"#0645AD"}}>https://adssettings.google.com/authenticated</a>).
                <br/><br/>
                Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisert.
                </p>

                <h3>Facebook-Pixel, Custom Audiences und Facebook-Conversion</h3>
                <p>
                Innerhalb unseres Onlineangebotes wird aufgrund unserer berechtigten Interessen an Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes und zu diesen Zwecken das sog. "Facebook-Pixel" des sozialen Netzwerkes Facebook, welches von der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA, bzw. falls Sie in der EU ansässig sind, Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"), eingesetzt.
                <br/><br/>
                Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" style={{ color:"#0645AD"}}>https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>).
                <br/><br/>
                Mit Hilfe des Facebook-Pixels ist es Facebook zum einen möglich, die Besucher unseres Onlineangebotes als Zielgruppe für die Darstellung von Anzeigen (sog. "Facebook-Ads") zu bestimmen. Dementsprechend setzen wir das Facebook-Pixel ein, um die durch uns geschalteten Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch ein Interesse an unserem Onlineangebot gezeigt haben oder die bestimmte Merkmale (z.B. Interessen an bestimmten Themen oder Produkten, die anhand der besuchten Webseiten bestimmt werden) aufweisen, die wir an Facebook übermitteln (sog. „Custom Audiences“). Mit Hilfe des Facebook-Pixels möchten wir auch sicherstellen, dass unsere Facebook-Ads dem potentiellen Interesse der Nutzer entsprechen und nicht belästigend wirken. Mit Hilfe des Facebook-Pixels können wir ferner die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke nachvollziehen, in dem wir sehen ob Nutzer nachdem Klick auf eine Facebook-Werbeanzeige auf unsere Website weitergeleitet wurden (sog. „Conversion“).
                <br/><br/>
                Die Verarbeitung der Daten durch Facebook erfolgt im Rahmen von Facebooks Datenverwendungsrichtlinie. Dementsprechend generelle Hinweise zur Darstellung von Facebook-Ads, in der Datenverwendungsrichtlinie von Facebook: <a href="https://www.facebook.com/policy.php" style={{ color:"#0645AD"}}>https://www.facebook.com/policy.php</a>. Spezielle Informationen und Details zum Facebook-Pixel und seiner Funktionsweise erhalten Sie im Hilfebereich von Facebook: <a href="https://www.facebook.com/business/help/651294705016616" style={{ color:"#0645AD"}}>https://www.facebook.com/business/help/651294705016616</a>.
                <br/><br/>
                Sie können der Erfassung durch den Facebook-Pixel und Verwendung Ihrer Daten zur Darstellung von Facebook-Ads widersprechen. Um einzustellen, welche Arten von Werbeanzeigen Ihnen innerhalb von Facebook angezeigt werden, können Sie die von Facebook eingerichtete Seite aufrufen und dort die Hinweise zu den Einstellungen nutzungsbasierter Werbung befolgen: <a href="https://www.facebook.com/settings?tab=ads" style={{ color:"#0645AD"}}>https://www.facebook.com/settings?tab=ads</a>. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.
                <br/><br/>
                Sie können dem Einsatz von Cookies, die der Reichweitenmessung und Werbezwecken dienen, ferner über die Deaktivierungsseite der Netzwerkwerbeinitiative (<a href="http://optout.networkadvertising.org/" style={{ color:"#0645AD"}}>http://optout.networkadvertising.org/</a>) und zusätzlich die US-amerikanische Webseite (<a href="http://www.aboutads.info/choices" style={{ color:"#0645AD"}}>http://www.aboutads.info/choices</a>) oder die europäische Webseite (<a href="http://www.youronlinechoices.com/uk/your-ad-choices/" style={{ color:"#0645AD"}}>http://www.youronlinechoices.com/uk/your-ad-choices/</a>) widersprechen.
                </p>

                <h3>
                    Onlinepräsenzen in sozialen Medien
                </h3>
                <p>
                Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen informieren zu können. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.
                <br/><br/>
                Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.
                </p>

                <h3>
                    Einbindung von Diensten und Inhalten Dritter
                </h3>
                <p>
                Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).
                <br/><br/>
                Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.
                </p>

                <h3>Vimeo</h3>
                <p>
                Wir können die Videos der Plattform “Vimeo” des Anbieters Vimeo Inc., Attention: Legal Department, 555 West 18th Street New York, New York 10011, USA, einbinden. Datenschutzerklärung: <a href="https://vimeo.com/privacy" style={{ color:"#0645AD"}}>https://vimeo.com/privacy</a>. WIr weisen darauf hin, dass Vimeo Google Analytics einsetzen kann und verweisen hierzu auf die Datenschutzerklärung (<a href="https://www.google.com/policies/privacy" style={{ color:"#0645AD"}}>https://www.google.com/policies/privacy</a>) sowie Opt-Out-Möglichkeiten für Google-Analytics (<a href="http://tools.google.com/dlpage/gaoptout?hl=de" style={{ color:"#0645AD"}}>http://tools.google.com/dlpage/gaoptout?hl=de</a>) oder die Einstellungen von Google für die Datennutzung zu Marketingzwecken (<a href="https://adssettings.google.com/" style={{ color:"#0645AD"}}>https://adssettings.google.com/</a>.).
                </p>

                <h3>Youtube</h3>
                <p>
                Wir binden die Videos der Plattform “YouTube” des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" style={{ color:"#0645AD"}}>https://www.google.com/policies/privacy/</a>, Opt-Out: <a href="https://adssettings.google.com/authenticated" style={{ color:"#0645AD"}}>https://adssettings.google.com/authenticated</a>.
                </p>

                <h3>Google Fonts</h3>
                <p>
                Wir binden die Schriftarten ("Google Fonts") des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" style={{ color:"#0645AD"}}>https://www.google.com/policies/privacy/</a>, Opt-Out: <a href="https://adssettings.google.com/authenticated" style={{ color:"#0645AD"}}>https://adssettings.google.com/authenticated</a>.
                </p>

                <h3>Google ReCaptcha</h3>
                <p>
                Wir binden die Funktion zur Erkennung von Bots, z.B. bei Eingaben in Onlineformularen ("ReCaptcha") des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" style={{ color:"#0645AD"}}>https://www.google.com/policies/privacy/</a>, Opt-Out: <a href="https://adssettings.google.com/authenticated" style={{ color:"#0645AD"}}>https://adssettings.google.com/authenticated</a>.
                </p>

                <h3>Google Maps</h3>
                <p>
                Wir binden die Landkarten des Dienstes “Google Maps” des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Zu den verarbeiteten Daten können insbesondere IP-Adressen und Standortdaten der Nutzer gehören, die jedoch nicht ohne deren Einwilligung (im Regelfall im Rahmen der Einstellungen ihrer Mobilgeräte vollzogen), erhoben werden. Die Daten können in den USA verarbeitet werden. Datenschutzerklärung: <a href="https://www.google.com/policies/privacy/" style={{ color:"#0645AD"}}>https://www.google.com/policies/privacy/</a>, Opt-Out: <a href="https://adssettings.google.com/authenticated" style={{ color:"#0645AD"}}>https://adssettings.google.com/authenticated</a>.
                </p>

                <h3>
                    Verwendung von Facebook Social Plugins
                </h3>
                <p>
                Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social Plugins ("Plugins") des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird ("Facebook"). Die Plugins können Interaktionselemente oder Inhalte (z.B. Videos, Grafiken oder Textbeiträge) darstellen und sind an einem der Facebook Logos erkennbar (weißes „f“ auf blauer Kachel, den Begriffen "Like", "Gefällt mir" oder einem „Daumen hoch“-Zeichen) oder sind mit dem Zusatz "Facebook Social Plugin" gekennzeichnet. Die Liste und das Aussehen der Facebook Social Plugins kann hier eingesehen werden: <a href="https://developers.facebook.com/docs/plugins/" style={{ color:"#0645AD"}}>https://developers.facebook.com/docs/plugins/</a>.
                <br/><br/>
                Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten (<a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" style={{ color:"#0645AD"}}>https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>).
                <br/><br/>
                Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enthält, baut sein Gerät eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an das Gerät des Nutzers übermittelt und von diesem in das Onlineangebot eingebunden. Dabei können aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher entsprechend unserem Kenntnisstand.
                <br/><br/>
                Durch die Einbindung der Plugins erhält Facebook die Information, dass ein Nutzer die entsprechende Seite des Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button betätigen oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Gerät direkt an Facebook übermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die Möglichkeit, dass Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine anonymisierte IP-Adresse gespeichert.
                <br/><br/>
                Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz der Privatsphäre der Nutzer, können diese den Datenschutzhinweisen von Facebook entnehmen: <a href="https://www.facebook.com/about/privacy/" style={{ color:"#0645AD"}}>https://www.facebook.com/about/privacy/</a>.
                <br/><br/>
                Wenn ein Nutzer Facebookmitglied ist und nicht möchte, dass Facebook über dieses Onlineangebot Daten über ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich vor der Nutzung unseres Onlineangebotes bei Facebook ausloggen und seine Cookies löschen. Weitere Einstellungen und Widersprüche zur Nutzung von Daten für Werbezwecke, sind innerhalb der Facebook-Profileinstellungen möglich: <a href="https://www.facebook.com/settings?tab=ads" style={{ color:"#0645AD"}}>https://www.facebook.com/settings?tab=ads</a> oder über die US-amerikanische Seite <a href="http://www.aboutads.info/choices/" style={{ color:"#0645AD"}}>http://www.aboutads.info/choices/</a> oder die EU-Seite <a href="http://www.youronlinechoices.com/" style={{ color:"#0645AD"}}>http://www.youronlinechoices.com/</a>. Die Einstellungen erfolgen plattformunabhängig, d.h. sie werden für alle Geräte, wie Desktopcomputer oder mobile Geräte übernommen.
                <br/><br/>
                <a href="https://datenschutz-generator.de" style={{ color:"#0645AD"}}>Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke</a>
                </p>


            </div>
        </React.Fragment>)
    } else {
        return (<React.Fragment>
            <div style={{ margin: 3 + 'em' }} >

                <h2>Privacy policy</h2>

                <p>
                    At DEPRAG SCHULZ GMBH u. CO. (hereinafter referred to as DEPRAG) your privacy is respected. All data collected is used to improve our range 
                    of products and services. Data protection and data security for customers and users have a high priority for DEPRAG. The protection of your 
                    personal data throughout our business processes is therefore a matter of particular concern to us.
                    
                    <br /><br />
                    
                    In accordance with Article 13 of the EU Data Protection Regulation (EU-DSGVO), this data protection declaration explains what information 
                    is collected on our website and the use of DEPRAG GraphViewer CLOUD through your visit and how this information is used. However, the 
                    declaration does not apply to websites of other companies which contain a link to this website or to which our website has provided links.
                </p>

                <h3>
                    1. Collection and processing of personal data
                </h3>

                <p>
                    When you visit our website, our web servers temporarily store access data by default, i.e. the IP address of your access, the Session_ID 
                    to identify your connection session, and the date and time of the visit.The legal basis for the temporary storage of the data is Art. 6 
                    para. 1 lit. f) EU-DSGVO. This data is deleted after the session has ended in accordance with legal regulations. In addition, personal data 
                    is only stored if you provide it to us voluntarily, e.g. as part of a registration, an order and the associated order processing, a survey, 
                    a competition or an online application on the basis of Art. 6 para. 1 lit. a) or Art. 6 para. 1 lit. b) EU-DSGVO.
                    You will be informed about the purpose of the data collection in the respective entry and contact forms. You have the option of deleting your
                    registration data in your customer account or via our customer service at any time.

                </p>

                <h3>
                    2. Note on data transfer to third countries
                </h3>

                <p>
                    To ensure the high accessibility of our customer service, we are selectively supported by service providers outside the area of application 
                    of the EU-DSGVO. We have concluded contracts with the service providers in accordance with Article 44 of the EU Data Protection Regulation in 
                    conjunction with the EU standard contractual clauses and regularly assure ourselves that our very high data protection requirements are also 
                    fully met.
                </p>

                <h3>
                    3. Use and forwarding of personal data and purpose limitation
                </h3>

                <p>
                    The processing and forwarding of your data is based on the following legal grounds: Art. 6 para. 1 lit. a) EU-DSGVO for all processing 
                    requiring consent, Art. 6 para. 1 lit. b) EU-DSGVO for contract fulfilment, Art. 6 para. 1 lit. c) EU-DSGVO for consent management to fulfil 
                    legal obligations and Art. 6 para. 1 lit. f) EU-DSGVO for further processing with legitimate interest.
                    <br /><br />

                    We use the data you provide to fulfil and process your order in accordance with Art. 6 para. 1 lit. b) EU-DSGVO. Your data will be used in 
                    accordance with the applicable data protection regulations solely for the purpose associated with the respective collection and to protect our 
                    own legitimate business interests pursuant to Art. 6 (1) f) EU-DSGVO, in particular the technical administration of the websites. We will only 
                    use this data for product-related surveys and marketing purposes if you have given us your prior consent in accordance with Art. 6 para. 1 
                    lit. a) EU-DSGVO. You can revoke any consent you may have given at any time with effect for the future.
                </p>

                <h4>
                    Transmission of data to third parties
                </h4>

                <p>
                    A transfer of personal data to government agencies will only take place within the framework of mandatory legal provisions. Your data will 
                    only be passed on to private third parties if you have expressly consented to this, if there is a legal obligation to do so or if this is
                     necessary to enforce our rights, in particular to enforce claims arising from the contractual relationship. In addition, we pass on your 
                     data to third parties insofar as this is necessary in the context of the use of the website and the processing of contracts (also outside 
                     the website), namely the processing of your orders.
                </p>

                <h3>
                    4. Use of cookies
                </h3>

                <p>
                    In order to enabl you to use certain functions when visiting our website and to make it more attractive, we use so-called cookies on various 
                    pages.
                    <br /><br />

                    Cookies are small text files that are stored on your terminal device. Some of the cookies we use are deleted after the end of the browser 
                    session, i.e. after you close your browser (so-called session cookies). Other cookies remain on your terminal device and enable us or our 
                    partner companies to recognise your browser on your next visit (persistent cookies). Further information and individual cookie settings here.

                    <br /><br />

                    When calling up the pages, the user is informed about the use of cookies for analysis purposes and his consent to the processing of personal 
                    data used in this context is obtained. To fulfil our legal obligation, we use an application specific solution, which stores 
                    consent on the basis of Art. 6 (1) lit. c) EU-DSGVO in order to comply with the documentation obligations pursuant to Art. 7 (1) EU-DSGVO.

                    <br /><br />
                    You can set your browser so that you are informed about the setting of cookies and decide individually whether to accept them or generally 
                    exclude cookies. If you do not accept session cookies, the functionality may be limited.
                </p>

                <h3>
                    5. Collection of anonymous user data
                </h3>

                <p>
                    This website uses web analysis technologies from Microsoft on the basis of Art. 6 para. 1 lit. a) and Art. 6 para. 1 lit. f) EU-DSGVO for 
                    marketing and optimisation purposes, from which user profiles are created using pseudonyms. These usage profiles are used to analyse visitor 
                    behaviour and are evaluated to improve and design our offer in line with requirements. The pseudonymised user profiles are not combined with 
                    personal data about the bearer of the pseudonym without the express consent of the person concerned, which must be given separately. Further 
                    information on the individual marketing and analysis cookies can be found here.
                    <br /><br />

                    Further information on terms of use and data protection can be found here: 

                    <a href="https://learn.microsoft.com/en-us/azure/azure-monitor/app/app-insights-overview?tabs=net"
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{color:'#32992c'}}> Microsoft Application Insights</a>

                </p>

                <h3>
                    6. Security measures
                </h3>

                <p>
                    DEPRAG uses extensive technical and operational security measures to protect your personal data managed by us against misuse, accidental or 
                    intentional manipulation or against access by unauthorised persons. Our security procedures are continuously improved in line with 
                    technological developments.
                </p>

                <h3>
                    7. Data subject rights
                </h3>

                <p>
                    When processing your personal data, the EU General Data Protection Regulation grants you certain rights:
                </p>

                <div style={{ marginLeft: 3 + 'em' }} >
                    <h3 >
                        1. Right of access (Art. 15 EU GDPR):
                    </h3>
                    <p>
                        You have the right to request confirmation as to whether personal data relating to you is being processed. If this is the case, you have 
                        the right to be informed about this personal data and to receive the information listed in detail in Art. 15 EU-DSGVO.
                    </p>

                    <h3 >
                        2. Right to rectification (Art. 16 EU GDPR):
                    </h3>

                    <p>
                        You have the right to request without undue delay the rectification of any inaccurate personal data concerning you and, where applicable, 
                        the completion of any incomplete personal data.
                    </p>

                    <h3 >
                        3. Right to erasure (Art. 17 EU GDPR):
                    </h3>

                    <p>
                        You also have the right to request that personal data concerning you be deleted without delay, provided that one of the reasons listed 
                        in detail in Art. 17 EU-DSGVO applies, e.g. if the data is no longer required for the purposes pursued.
                    </p>

                    <h3 >
                        4. Right to information (Art. 19 EU GDPR):
                    </h3>

                    <p>
                        If you have asserted the right to rectification, erasure or restriction of processing against the controller, the controller is obliged 
                        to inform all recipients to whom the personal data concerning you have been disclosed of this rectification or erasure of the data or 
                        restriction of processing, unless this proves impossible or involves a disproportionate effort.

                        <br /><br />

                        You have the right to be informed about these recipients.

                    </p>


                    <h3 >
                        5. Right to restriction of processing (Art. 18 EU-DSGVO):
                    </h3>

                    <p>
                        You have the right to request the restriction of processing if one of the conditions listed in Art. 18 EU GDPR applies, e.g. if you have 
                        objected to the processing, for the duration of any review.
                    </p>

                    <h3 >
                        6. Right to data portability (Art. 20 EU-DSGVO):
                    </h3>

                    <p>
                        In certain cases, which are listed in detail in Art. 20 EU-DSGVO, you have the right to receive the personal data concerning you in a 
                        structured, common and machine-readable format or to request the transfer of this data to a third party.
                    </p>

                    <h3 >
                        7. Revocation of consent (Art. 7 para. 3 EU-DSGVO):
                    </h3>

                    <p>
                        You have the right to revoke your consent at any time. This means that we will no longer continue the data processing that we previously 
                        carried out on the basis of your consent. Your revocation does not affect the lawfulness of the processing of the data that has already 
                        taken place. n.
                    </p>

                    <h3 >
                        8. Right to lodge a complaint with a supervisory authority (Art. 77 EU-DSGVO):
                    </h3>

                    <p>
                        According to Art. 77 EU-DSGVO, you have the right to lodge a complaint with a supervisory authority if you are of the opinion that the 
                        processing of the data concerning you violates data protection regulations. The right to lodge a complaint may in particular be asserted 
                        before a supervisory authority in the Member State of your place of residence, your place of work or the place of the alleged infringement.
                    </p>

                    <h3 >
                        9. Right to object (Art. 21 EU-DSGVO):
                    </h3>
                    <p>
                        You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data concerning 
                        you which is carried out on the basis of Article 6(1)(e) or Article 6(1)(f) EU GDPR, in accordance with Article 21(2) EU GDPR.
                        <br /><br />

                        The controller shall no longer process the personal data concerning you unless it can demonstrate compelling legitimate grounds for the 
                        processing which override your interests, rights and freedoms, or the processing serves the purpose of asserting, exercising or defending 
                        legal claims.

                        <br /><br />

                        If the personal data concerning you is processed for the purpose of direct marketing, you have the right to object at any time to the 
                        processing of personal data concerning you for the purpose of such marketing; this also applies to profiling insofar as it is related 
                        to such direct marketing.
                    </p>

                </div>
                <br />
                <h3>
                    Responsible party for data processing
                </h3>

                <p>
                    DEPRAG SCHULZ GMBH u. CO.<br />
                    Carl-Schulz-Platz 1 | 92224 Amberg<br />
                    Deutschland
                    Tel: +49 9621 371-0<br />
                    Fax +49 9621 371-120<br />
                </p>

                <p>E-Mail: info@deprag.de</p>

                <p>CEO:<br />
                    Dr. Erik Hallmann / Dr.-Ing. Rolf Pfeiffer</p>


                <h3>
                    Data protection officer of the responsible party
                </h3>
                <p>
                    Bastian Blank<br />
                    Carl-Schulz-Platz 1 | 92224 Amberg<br />
                    Deutschland
                    Tel: +49 9621 371-208<br />
                </p>

                <p>E-Mail: datenschutz@deprag.de</p>



            </div>
        </React.Fragment>)
    }
}