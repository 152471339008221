import { configureStore, ThunkAction, Action, combineReducers, PreloadedState } from '@reduxjs/toolkit';
import settingsReducer from '../../Features/Settings/Redux/SettingsSlice';
import storage from 'redux-persist/lib/storage';
import languageFileReducer from '../Internationalisation/Redux/LanguageFileSlice';
import { persistReducer, createMigrate } from 'redux-persist';

const reducers = combineReducers({
  settings: settingsReducer,
  languageFile: languageFileReducer, 
});

const migrations = {
  1: (state) => {
    return {
      ...state
    } 
  }
}

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['settings'],
  migrate: createMigrate(migrations, { debug: false }),
  transforms: [
 
  ]
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //  serializableCheck: {
      //    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      //  }
      serializableCheck: false,
      immutableCheck: false
    }),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: reducers,
    preloadedState
  })
}


export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
