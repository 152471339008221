import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { Box, Grid, Typography, Button } from "@mui/material";
import { Element } from "react-scroll";
import Cover from "../images/prontobau.png";
import Zoom from "react-reveal/Zoom";
import Lyme from "../images/lyme.png";
import Question from "../images/questionmark.png";
import Fade from "react-reveal/Fade";
import Helmet from "react-helmet"

export const ProjectsSection = () => {

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Element name="projects">
                    <Helmet>
                    <title>Projekte</title>
                    <meta name="description" content="Besuchen Sie unser Portfolio von bereits realisierten Projekten"/>
                    </Helmet>
                    <Box component={"div"} sx={{
                        id: "projects",
                        flex: 1,
                        px: 4,
                        py: 8,
                        display: 'flex',
                        overflow: 'hidden',
                        justifyContent: 'center',
                        minHeight: 'calc(100vh - 150px)',
                        alignItems: 'center'
                    }}  >
                        <Fade>
                        <Grid container maxWidth={'lg'} sx={{ display: 'flex', gap: 3 }}>
                            <Grid item xs={12} textAlign={'center'}>
                                <Typography variant="h4" sx={{fontWeight: 600}}>Projekte</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{marginTop: 10}}>
                            <Grid container spacing={6}>
                                <Grid item xs={12} md={6} lg={4} sx={{display: 'flex', justifyContent: 'center', mt: {xs: 2}}}>
                                    <Zoom>
                                    <Box component={'div'} sx={{maxWidth: '400px', height: '400px', backgroundColor:'#fff', borderRadius: 5, position: 'relative', display: 'flex', p: 3, flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <Box component={'div'} sx={{width: '100%', height: '250px', borderRadius: 5, backgroundImage: `url(${Cover})`, backgroundPosition: 'center', backgroundSize: 'cover', position: 'absolute',mt: 0, left: {xs: 0, md: -40}, top:-40}}/>
                                    <Typography variant="h5" sx={{pt: '200px', fontWeight: 500}}>
                                        PRONTO Bau GmbH
                                    </Typography>
                                    <Typography variant="body1" sx={{color: 'rgba(0,0,0,0.7)'}}>
                                    Eine einfache Webseite für ein Bauunternehmen aus Österreich
                                    </Typography>
                                    <Button variant="contained" color="dark" href="https://prontobau.botnatic.com/" target="_blank" sx={{width: 'fit-content', px: 4, py: 1, backgroundColor: '#353535'}}>
                                        Ansehen
                                    </Button>   
                                    </Box>
                                    </Zoom>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4} sx={{display: 'flex', justifyContent: 'center', mt: {xs: 2}}}>
                                    <Zoom delay={30}>
                                    <Box component={'div'} sx={{maxWidth: '400px', height: '400px', backgroundColor: '#fff', borderRadius: 5, position: 'relative', display: 'flex', p: 3, flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <Box component={'div'} sx={{width: '100%', height: '250px', borderRadius: 5, backgroundImage: `url(${Lyme})`, backgroundPosition: 'center', backgroundSize: 'cover', position: 'absolute', mt: 0, left: {xs: 0, md: -40}, top: -40}}/>
                                    <Typography variant="h5" sx={{pt: '200px', fontWeight: 500}}>
                                        Lyme
                                    </Typography>
                                    <Typography variant="body1" sx={{color: 'rgba(0,0,0,0.7)'}}>
                                    Ein Bot inklusive Dashboard für die Online Plattform Discord
                                    </Typography>
                                    <Button variant="contained" color="dark" href="https://lyme.gg/" target="_blank" sx={{width: 'fit-content', px: 4, py: 1, backgroundColor: '#353535'}}>
                                        Ansehen
                                    </Button>   
                                    </Box>
                                    </Zoom>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4} sx={{display: 'flex', justifyContent: 'center', mt: {xs: 2}}}>
                                    <Zoom delay={60}>
                                    <Box component={'div'} sx={{maxWidth: '400px', height: '400px', backgroundColor: '#fff', borderRadius: 5, position: 'relative', display: 'flex', p: 3, flexDirection: 'column', justifyContent: 'space-between'}}>
                                    <Box component={'div'} sx={{width: '100%', height: '250px', borderRadius: 5, backgroundImage: `url(${Question})`, backgroundColor: '#181818', backgroundPosition: 'center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat', position: 'absolute',mt: 0, left: {xs: 0, md: -40}, top: -40}}/>
                                    <Typography variant="h5" sx={{pt: '200px', fontWeight: 500}}>
                                        Demnächst
                                    </Typography>
                                    <Typography variant="body1" sx={{color: 'rgba(0,0,0,0.7)'}}>
                                    Wir arbeiten zur Zeit an neuen Projekten um unser Portfolio zu erweitern
                                    </Typography>
                                    <Button variant="contained" color="dark" disabled sx={{width: 'fit-content', px: 4, py: 1, backgroundColor: '#353535'}}>
                                        Ansehen
                                    </Button>   
                                    </Box>
                                    </Zoom>
                                </Grid>
                            </Grid>
                            </Grid>
                        </Grid>
                        </Fade>
                    </Box>
                </Element>
            }
        </LanguageConsumer>
    )
}