import Lottie, { Options } from 'react-lottie';
import successIcon from './JSON/Marketing.json';
import Zoom from "@mui/material/Zoom";

export const MarketingAnimation = ({ size, speed = .8, icon = successIcon}) => {

    const defaultOptions = {
        loop: false,
        autoplay: true, 
        animationData: icon,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      } as Options;

    return(
      <div style={{marginTop: 20}}>
        <Zoom in={true} unmountOnExit>
        <span>
          <Lottie speed={speed} options={{...defaultOptions, loop: true}}
          height={100}
          width={110}/>
        </span> 
        </Zoom>
      </div>
    )
}
