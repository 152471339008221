/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from 'react';
import {
  Routes,
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import './App.css';
import Footer from './Layout/Footer/Footer';
import { useAppDispatch, useAppSelector } from './Infrastructure/Redux/hooks';
import { themeLight } from './Layout/Theme/ThemeProvider';
import { LanguageConsumer } from './Infrastructure/Internationalisation/TranslationService';
import { Box } from '@mui/material';
import { Privacy } from './Features/Privacy/Privacy';
import { Imprint } from './Features/Imprint/Imprint';
import { loadCsv } from './Infrastructure/Internationalisation/Redux/LanguageFileSlice';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import Navigation from './Layout/Navigation/Navigation';
import { LandingPage } from './Features/LandingPage/LandingPage';
import { Toaster } from "react-hot-toast";
import ScrollToTop from './Infrastructure/ScrollToTopRouter/ScrollToTop';
import { WhoWeAreSection } from './Features/LandingPage/sections/WhoWeAreSection';
import { ProjectsSection } from './Features/LandingPage/sections/ProjectsSection';
import { ContactSection } from './Features/LandingPage/sections/ContactSection';

function App() {
  const dispatch = useAppDispatch();
  const languageFileWasLoaded = useAppSelector(store => store.languageFile.languageFileWasLoaded);

  useEffect(() => {
    dispatch(loadCsv());
  }, []);

  return (
    <Fragment>
      {languageFileWasLoaded &&
        <LanguageConsumer>
          {({ getTranslatedText }) =>

            <ThemeProvider theme={themeLight}>
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  minHeight: '100vh',
                  alignItems: "stretch",
                  transition: "background-color 0.3s",
                  backgroundColor: "#f3f3f3",
                }}
              >

                <CssBaseline/>

                <Router>
                  <ScrollToTop/>
                  <Navigation />
                  <Toaster position='bottom-right' toastOptions={{error: {style: {background: '#d32f2f', color: '#fff'}, iconTheme:{primary: '#fff', secondary: '#d32f2f'}}}}/>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <Routes>
                      <Route path="/" element={<LandingPage />}/>
                      <Route path="/about" element={<WhoWeAreSection />}/>
                      <Route path="/projects" element={<ProjectsSection />}/>
                      <Route path="/contact" element={<ContactSection/>}/>
                      <Route path="/imprint" element={<Imprint/>}/>
                      <Route path="/privacy" element={<Privacy/>}/>
                    </Routes>
                  </QueryParamProvider>
                


                  <Footer />
                </Router>
              </Box>
            </ThemeProvider>

          }
        </LanguageConsumer>
      }
    </Fragment>
  );

}

export default App;
