import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { Box, Grid, Typography, IconButton, Divider } from "@mui/material";
import { Element } from "react-scroll";
import Slide from "react-reveal/Slide";
import YImage from "../images/devices.jpg";
import Carousel from 'react-material-ui-carousel'
import ViewCarouselIcon from '@mui/icons-material/AppShortcut';
import PaletteIcon from '@mui/icons-material/BarChart';
import ShowChartIcon from '@mui/icons-material/Terminal';
import { SuccessAnimation } from "../../../Infrastructure/Animations/SuccessAnimation";
import { CongratsAnimation } from "../../../Infrastructure/Animations/CongratsAnimation";
import { MarketingAnimation } from "../../../Infrastructure/Animations/MarketingAnimation";
import Helmet from "react-helmet";

export const HomeSection = () => {

    const array = [
        {
            image: YImage,
            icon: <SuccessAnimation size={130} speed={0.8} />,
            iconSmall: <ShowChartIcon/>,
            key: 1,
            header: "SOFTWAREENTWICKLUNG",
            subtitle: "Wir entwickeln jegliche Art von Anwendung und integrieren dabei einzigartige Inhalte und Ihr Branding."
        },
        {
            image: YImage,
            icon: <CongratsAnimation size={130} speed={0.8}/>,
            iconSmall: <ViewCarouselIcon />,
            key: 2,
            header: "WEBDESIGN",
            subtitle: "Wir sind spezialisiert darauf, den schwierigen Balanceakt des Entwerfens einer guten Webseite zu bewältigen."
        },
        {
            image: YImage,
            icon: <MarketingAnimation size={92} />,
            iconSmall: <PaletteIcon />,
            key: 3,
            header: "DIGITAL MARKETING",
            subtitle: "Marketing ist von zentraler Bedeutung für den Erfolg eines Unternehmens. Wir unterstützen Sie dabei, Ihre Webseite so zu optimieren, dass sie von Suchmaschinen besser gefunden und damit effektiver vermarktet wird."
        },
        /*{
            image: YImage,
            icon: <UiAnimation size={130} speed={0.8}/>,
            iconSmall: <ComputerIcon />,
            key: 4,
            header: "UI / UX FREUNDLICH",
            subtitle: "Durch UI / UX freundliches Design finden sich Besucher Ihrer Webseite schnell und problemlos zurecht."
        }*/
    ]

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Element name="home" style={{overflow: 'hidden'}}>
                    <Helmet>
                    <title>Botnatic</title>
                    <meta name="description" content="Botnatic ist Ihr Ansprechpartner für professionelle Unternehmens-Webseiten und andere Softwarelösungen"/>
                    </Helmet>
                    <Box component={"div"} sx={{
                        id: "home",
                        flex: 1,
                        borderRadius: 4,
                        p: 2,
                        display: 'flex',
                        minHeight: 'calc(100vh - 150px)',
                        justifyContent: 'center',
                    }}  >
                        <Grid container sx={{ display: 'flex', maxWidth: 'xl', zIndex: 2}}>
                            <Grid item xs={12} md={6} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', p: {xs: 2, lg: 10}, textAlign: 'center'}}>
                                <Slide left>
                                <Typography variant='h3' color={ '#353535'} sx={{ fontWeight: '700'}}>
                                Ihr Auftritt ist unser Auftrag.
                                </Typography>
                                <Typography variant='h6' color={ '#444'} sx={{ fontWeight: '400', mt: 2 }}>
                                Sie benötigen eine Webseite oder eine speziell angepasste Softwarelösung? Wir setzen Ihre Vorstellungen um.
                                </Typography>
                                </Slide>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{display: 'flex', p: {xs: 2, lg: 10}, position: 'relative', alignItems: 'center', justifyContent: {xs: 'center', md: 'flex-start'}}}>
                                <Typography sx={{ fontWeight: 700, color: 'rgba(35, 35, 35, 0.2)', textOrientation: 'mixed', writingMode: 'vertical-lr', position: {xs: 'absolute', md: 'fixed'}, top: 0, height: '100vh', flexWrap: 'nowrap', whiteSpace: 'nowrap', display: 'none', gap: 4}}><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span><span>Botnatic</span></Typography>
                                <Carousel sx={{ width: 350, ml: {xs: 0, md: 20}}}
                                          autoPlay={true}
                                          animation={"fade"}
                                          navButtonsAlwaysVisible={false}
                                          stopAutoPlayOnHover={true}
                                          interval={8000}
                                          >
                                    {
                                        array.map((data) => (
                                    <Box key={data.key} component={'div'} sx={{height: 500, maxWidth: 350, borderRadius: 5, position: 'relative', overflow: 'hidden', backgroundColor: 'transparent'}}>
                                    <Box id="whitebackgroundupper" component={'div'} sx={{position: 'absolute', top: 0, height: 60, left: 60, width: 290, backgroundColor: '#fff'}}/>
                                    <Box id="whitebackgroundlower" component={'div'} sx={{position: 'absolute', top: 60, height: 440,  left: 0, maxWidth: 350, width: '100%', backgroundColor: '#fff'}}/>
                                    <Box component={'div'} sx={{height: 40, width: 40, backgroundColor: '#fff', borderTopLeftRadius: 0, zIndex: 6, position: 'absolute', top: 0, left: 0}}>
                                        <Box component={'div'} sx={{height: 60, width: 60, borderBottomRightRadius: 20, backgroundColor: '#fff', zIndex: 6, position: 'absolute', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <IconButton>
                                                {data.iconSmall}
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box id="image" component={'div'} sx={{position: 'absolute', p: 10, height: 200, width: 'calc(100% - 40px)', borderRadius: 5, backgroundColor: '#000', backgroundImage: 'linear-gradient(315deg, #000 0%, #414141 94%)', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundPosition: 'center', backgroundSize: 'contain', top: 20, left: 20, zIndex: 2}}>
                                            {data.icon}
                                        </Box>
                                    <Divider sx={{position: 'absolute', top: 300, left: 20, zIndex: 4, border: 'none', backgroundColor: '#444', height: 4, borderRadius: 10, width: 25}}/>
                                    <Typography sx={{position: 'absolute', top: 320, left: 20, fontWeight: 500}}>{data.header}</Typography>
                                    <Typography variant="body2" sx={{position: 'absolute', top: 360, left: 20, maxWidth: 310}}>{data.subtitle}</Typography>
                                </Box>))
                                }
                                </Carousel>
                            </Grid>
                        </Grid>
                    </Box>
                </Element>
            }
        </LanguageConsumer>
    )
}