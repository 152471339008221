import './Style/Navigation.css';
import {AppBar, Box, Toolbar, Typography, Container, Menu, MenuItem} from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import PageBar from './Views/Pages';
import { LanguageConsumer } from '../../Infrastructure/Internationalisation/TranslationService';
import Logo from "./Assets/botnatic_dark.png";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useState } from 'react';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Fade from "react-reveal/Fade";

const Navigation = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
            <Fade>
                <AppBar position={"sticky"} enableColorOnDark={true} elevation={0} sx={{ border: 'none', transition: 'background-color 0.3s', backgroundColor: 'transparent'}}>
                    <Container maxWidth="xl" sx={{px: {xs: 0, md: 3}}}>
                        <Toolbar sx={{transition: 'all 0.3s',height: '80px'}}>
                            <Box component={"div"} sx={{flexGrow: 1, display: 'flex', justifyContent: "space-between"}}>
                              <Box component={'div'} sx={{width: '320px', display: {xs: 'none', md: 'flex'}, alignItems: 'center', justifyContent: 'flex-start'}}>
                              <PageBar />
                              </Box>
                                  <NavLink to={"/"} style={{display: 'flex', alignItems: 'center'}}>
                                      <Box component={'div'} sx={{backgroundImage: `url(${Logo})`, backgroundSize: 'cover', height: '34px', width: '34px', marginBottom: 0.5, mr: '-4px'}}/>
                                      <Typography variant='h5' sx={{color: '#444', fontWeight: 900}}>otnatic</Typography>
                                  </NavLink>

                            <Box component={"div"} sx={{ width: { xs: 'auto', md: '320px'}, display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '48px', borderRadius: 10, backgroundColor: 'rgba(232, 232, 232, 0.6)', cursor: 'pointer' }} onClick={() => navigate("/contact")}>
                                  <Typography sx={{p: '12px 16px', fontSize: '14px', fontWeight: 600, color: location.pathname === "/contact"? "#444" : "#8e8e8e", transition: 'all 0.2s'}}>KONTAKT</Typography>
                                  <Box component={'div'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', p: '12px 16px 12px 0px'}}> 
                                  <CallMadeIcon fontSize='small' sx={{color: location.pathname === "/contact"? "#444" : "#8e8e8e", transition: 'all 0.2s'}} />
                                  </Box>
                                </Box>
                                <Box component="div" sx={{ display: {xs: 'flex', md: 'none'}, alignItems: 'center', justifyContent: 'center', height: '48px', borderRadius: '50%', backgroundColor: 'rgba(232, 232, 232, 0.6)', cursor: 'pointer', p: '12px', ml: 2 }} onClick={handleClick}>
                                    <ClearAllIcon sx={{color: '#444'}}/>
                                </Box>

                                <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            backgroundColor: '#fff',
            opacity: 1!,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: '#fff',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
              backgroundColor: '#fff',
              opacity: '1 !important'
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {handleClose(); navigate("/");}} sx={{minWidth: 140, textAlign: 'center', fontWeight: location.pathname === "/"? 600: 400}}>
         Startseite
        </MenuItem>
        <MenuItem onClick={() => {handleClose(); navigate("/about");}} sx={{minWidth: 140, textAlign: 'center', fontWeight: location.pathname === "/about"? 600: 400}}>
         Über uns
        </MenuItem>
        <MenuItem onClick={() => {handleClose(); navigate("/projects");}} sx={{minWidth: 140, textAlign: 'center', fontWeight: location.pathname === "/projects"? 600: 400}}>
          Projekte
        </MenuItem>
      </Menu>
                            </Box>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                </Fade>
            }
        </LanguageConsumer>
    );
};

export default Navigation;