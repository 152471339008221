import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../Infrastructure/Redux/store';

export interface SettingsState {
  language: string,
  appTheme: string,
}

const getBrowserLanguage = () => {
  if(navigator.language.toLowerCase().startsWith("de")){
    return "de";
  }else{
    return "en";
  }
}

export const initialStateSettings: SettingsState = {
  language: getBrowserLanguage(),
  appTheme: "light",
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialStateSettings,
  reducers: {
    setAllSettings: (state, action) => {
      return {
        ...action.payload as SettingsState,
      }
    },
    setAppTheme: (state, action) => {
      state.appTheme = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {

  }
});

export const {setAllSettings, setLanguage, setAppTheme } = settingsSlice.actions;

export const selectLanguage = (state: RootState) => state.settings.language;

export default settingsSlice.reducer;
