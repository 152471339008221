import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { Box, Grid, Typography } from "@mui/material";
import { Element } from "react-scroll";
import Fade from "react-reveal/Fade";
import Tatjana from "../images/Tatjana_Portrait.png";
import Patrick from "../images/Patrick_Portrait.png";
import Helmet from "react-helmet"
import Zoom from "react-reveal/Slide";

export const WhoWeAreSection = () => {

    const team = [
        {
            name: "Patrick Gerstacker",
            role: "Inhaber / Software Entwickler",
            text: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus ullam consequatur eveniet modi.",
            alignStart: false,
            linkedIn: "https://www.linkedin.com/in/patrickgerstacker/",
            picture: Patrick,
            my: -11,
            mx: 0,
            left: true
        },
        {
            name: "Tatjana Riepl",
            role: "Managerin",
            text: "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Delectus ullam consequatur eveniet modi.",
            alignStart: true,
            linkedIn: "https://www.linkedin.com/in/tatjanariepl/",
            picture: Tatjana,
            my: -6,
            mx: 0,
            left: false
        }
    ]

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Element name="whoweare">
                    <Helmet>
                    <title>Über uns</title>
                    <meta name="description" content="Erfahren Sie mehr über Botnatic und das Team dahinter"/>
                    </Helmet>
                    <Box component={"div"} sx={{
                        id: "whoweare",
                        flex: 1,
                        px: 4,
                        py: 4,
                        display: 'flex',
                        overflow: 'hidden',
                        justifyContent: 'center',
                        minHeight: 'calc(100vh - 150px)',
                        alignItems: 'center'
                    }}  >
                        <Fade>
                        <Grid container maxWidth={'lg'} sx={{ display: 'flex', gap: 3 }}>
                            <Grid item xs={12} textAlign={'center'}>
                                <Typography variant="h4" sx={{fontWeight: 600, mt: {xs: 0, md: 6}}}>Über uns</Typography>
                            </Grid>
                            <Grid item xs={12} textAlign={'center'} justifyContent={'center'} display={'flex'}>
                                <Typography variant="body1" maxWidth={"lg"}>Im August 2022 gründete Patrick Gerstacker das Kleingewerbe Botnatic, um hochwertiges Webdesign und individuelle Softwarelösungen anzubieten. Mit fundiertem Fachwissen und Leidenschaft begann er, sein Unternehmen aufzubauen, um den wachsenden Bedarf an professionellen Lösungen in der IT-Branche zu füllen. Botnatic legt Wert auf individuelle Lösungen und enge Zusammenarbeit mit Kunden.<br/><br/> Die hohe Qualität der Arbeit, termingerechte Projektfertigstellung und hervorragender Kundenservice tragen zum Erfolg bei. Patrick Gerstacker und sein Team arbeiten hart daran, ihre Fähigkeiten ständig zu verbessern und sich über die neuesten Entwicklungen in der Webdesign- und Softwarebranche auf dem Laufenden zu halten. Sie sind davon überzeugt, dass Botnatic auch in Zukunft erfolgreich sein wird und freuen sich darauf, noch viele weitere Kunden glücklich zu machen.</Typography>
                            </Grid>
                            <Grid item xs={12} textAlign={'center'}>
                                <Typography variant="h4" sx={{fontWeight: 600, mt: 4}}>Unser Team</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{display: 'flex'}}>
                                <Grid container>
                            {
                                team.map((data) => (
                                    <Grid item key={data.name} md={6} xs={12} sx={{display: 'flex', p: 2, justifyContent: {xs: "center", md: data.alignStart? "flex-start" : "flex-end"}}}>
                                    <Zoom left={data.left} right={!data.left}>

                                    <Box component={'div'} sx={{height: '280px', width: '260px', borderRadius: 3, backgroundColor: '#fff', display: 'flex', flexDirection: 'column', p: 2, alignItems: 'center', gap: 0}}>
                                        <Box component={'div'} sx={{height: '160px', width: '160px', borderRadius: '50%', backgroundImage: `url(${data.picture}), linear-gradient(315deg, #252525 0%, #414141 94%)`, backgroundPosition: 'center', backgroundPositionY: data.my, backgroundPositionX: data.mx, backgroundSize: "cover"}}/>
                                        <Typography variant="h6" sx={{p: 0, m: 0, mt: 1}}>{data.name}</Typography>
                                        <Typography variant="body1" sx={{p: 0, m: 0, fontStyle: "italic"}}>{data.role}</Typography>

                                    </Box>
                                    </Zoom>

                                    </Grid>
                                ))
                            }
                            </Grid>
                            </Grid>

                        </Grid>
                        </Fade>
                    </Box>
                </Element>
            }
        </LanguageConsumer>
    )
}