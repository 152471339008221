import React from "react";
import { HomeSection } from './sections/HomeSection';

export const LandingPage = () => {

    return (
            <React.Fragment>
                <HomeSection/>
            </React.Fragment>
        )
}