import Lottie, { Options } from 'react-lottie';
import successIcon from './JSON/Success.json';
import Zoom from "@mui/material/Zoom";

export const SuccessAnimation = ({ size, speed = .8, icon = successIcon}) => {

    const defaultOptions = {
        loop: false,
        autoplay: true, 
        animationData: icon,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      } as Options;

    return(
      <div>
        <Zoom in={true} unmountOnExit>
        <span>
          <Lottie speed={speed} options={{...defaultOptions, loop: true}}
          height={size}
          width={size}/>
        </span> 
        </Zoom>
      </div>
    )
}
