import { Grow, Grid, Container } from "@mui/material";
import React from "react";
import { LanguageConsumer } from "../../Infrastructure/Internationalisation/TranslationService";
import { ImprintLanguage } from "./Languages/ImprintLanguage";
import Helmet from "react-helmet"

export const Imprint = () => {

    return (
        <LanguageConsumer>
        {({ getTranslatedText }) =>
                  <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                    <Helmet>
                    <title>Impressum</title>
                    <meta name="description" content="Botnatic - Inh. Patrick Gerstacker. Schlehenweg 4, 92256 Hahnbach. E-Mail: info@botnatic.com"/>
                    </Helmet>
            <React.Fragment>
                <Grow in={true} appear={true}>
                    <Grid container direction={'row'} spacing={2} >
                        <Grid item xs={12} md={12}>
                            <ImprintLanguage />
                        </Grid>
                    </Grid>
                </Grow>
            </React.Fragment>
            </Container>
            }
        </LanguageConsumer>
    );
}