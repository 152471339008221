import { LanguageConsumer } from "../../../Infrastructure/Internationalisation/TranslationService";
import { Box, Grid, Typography, TextField } from "@mui/material";
import { Element } from "react-scroll";
import CallMadeIcon from '@mui/icons-material/CallMade';
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide"
import { ContactAnimation } from "../../../Infrastructure/Animations/ContactAnimation";
import Helmet from "react-helmet";

export const ContactSection = () => {

    return (
        <LanguageConsumer>
            {({ getTranslatedText }) =>
                <Element name="home" style={{overflow: 'hidden'}}>
                    <Helmet>
                    <title>Kontakt</title>
                    <meta name="description" content="Haben Sie Fragen? Wir helfen Ihnen gerne. Senden Sie uns eine kurze Nachricht oder rufen Sie uns an!"/>
                    </Helmet>
                    <Box component={"div"} sx={{
                        id: "home",
                        flex: 1,
                        borderRadius: 4,
                        p: 2,
                        display: 'flex',
                        minHeight: 'calc(100vh - 150px)',
                        justifyContent: 'center'
                    }}  >
                        <Grid container sx={{ display: 'flex', maxWidth: 'xl', zIndex: 2}}>
                            <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'start', p: {xs: 2, md: 10}, flexDirection: 'column', gap: 4}}>
                                <Slide top>
                                <Typography variant="h3" color={ '#353535'} sx={{fontWeight: 700, textAlign: 'center'}}>Kontaktieren Sie uns</Typography>
                                <Box component={'div'} sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                            <ContactAnimation size={130} speed={0.8}/>

                                <Box component={"div"} sx={{width: 'fit-content', height: 'fit-content', display: 'flex', flexDirection: 'column', gap: 2, borderRadius: 4}}>
                                <a href="tel:491706572044">
                                <Typography sx={{display: 'flex', gap: 1, minWidth: '180px', color:  '#444'}}>
                                <PhoneIcon sx={{color:  '#444'}}/>
                                +49170 6572044
                                </Typography>
                                 </a>
                                <a href="mailto:info@botnatic.com">
                                    <Typography sx={{display: 'flex', alignItems: 'center', gap: 1, alignSelf: 'center', minWidth: '180px', color: '#444'}}>
                                        <MailIcon sx={{color:  '#444'}}/>
                                        info@botnatic.com
                                    </Typography>
                                </a>
                            </Box>

                            </Box>
                            </Slide>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{position: 'relative', maxHeight: 800}}>
                            <Fade>
                                <Box>
                            <Box component={'div'} sx={{backgroundColor: '#fff', display: 'flex', justifyContent: 'flex-start',p: {xs: 2, md: 4}, alignItems: 'center', height: '80px', width: {xs:'calc(100% - 100px)', md:'calc(100% - 200px)'}, borderTopLeftRadius: 30, borderTopRightRadius: 30}}>
                                <Typography variant="h6" sx={{fontWeight: 500}}>Füllen Sie das Formular aus:</Typography>
                            </Box>
                            <Box component={'div'} sx={{backgroundColor: '#fff', height: '80px', width: {xs:'100px', md:'200px'}, position: 'absolute', top: 0, right: 0}}>
                                <Box component={'div'} sx={{backgroundColor: '#f3f3f3', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80px', width: {xs:'100px', md:'200px'}, position: 'absolute', top: 0, right: 0, borderBottomLeftRadius: 50}}>
                                    <Typography variant="body2" sx={{fontWeight: 500}}>* erforderlich</Typography>
                                </Box>
                            </Box>
                            <Box component={'div'} sx={{backgroundColor: '#fff', width: '100%', p: {xs: 2, md: 4}, borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopRightRadius: 30, display: 'flex', flexDirection: 'column', gap: 2}}>
                                <TextField
                                        variant="outlined"
                                        color="secondary"
                                        label="Name"
                                        fullWidth
                                        required
                                        sx={{backgroundColor: '#f3f3f3'}}/>
                                <TextField
                                        variant="outlined"
                                        color="secondary"
                                        label="Unternehmen"
                                        fullWidth
                                        sx={{backgroundColor: '#f3f3f3'}}/>
                                <TextField
                                        variant="outlined"
                                        color="secondary"
                                        label="E-Mail Adresse"
                                        fullWidth
                                        required
                                        sx={{backgroundColor: '#f3f3f3'}}/>
                                <TextField
                                        variant="outlined"
                                        color="secondary"
                                        label="Nachricht"
                                        fullWidth
                                        required
                                        multiline
                                        rows={12}
                                        sx={{backgroundColor: '#f3f3f3'}}/>
                                        <Box component={'div'} sx={{height: '100%', width: '100%', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
                                            <Typography variant="body2" sx={{alignSelf: 'center', mr: 1}}>(Momentan nicht möglich)</Typography>
                                        <Box component="div" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '48px', width: 'fit-content', alignSelf: 'flex-end', borderRadius: 10, backgroundColor: '#353535', cursor: 'pointer' }}>
                                  <Typography sx={{p: '12px 16px', fontSize: '14px', fontWeight: 600, color: '#fff'}}>Anfrage senden</Typography>
                                  <Box component={'div'} sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', p: '12px 16px 12px 0px'}}> 
                                  <CallMadeIcon fontSize='small' sx={{color: '#fff'}} />
                                  </Box>
                                </Box>
                                        </Box>
                            </Box>
                            </Box>
                            </Fade>
                            </Grid>
                        </Grid>
                    </Box>
                </Element>
            }
        </LanguageConsumer>
    )
}