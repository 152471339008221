import Lottie, { Options } from 'react-lottie';
import congratsIcon from './JSON/Congrats.json';
import Zoom from "@mui/material/Zoom";

export const CongratsAnimation = ({ size, speed = .8}) => {

    const defaultOptions = {
        loop: false,
        autoplay: true, 
        animationData: congratsIcon,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      } as Options;

    return(
      <div>
        <Zoom in={true} unmountOnExit>
        <span>
          <Lottie speed={speed} options={{...defaultOptions, loop: true}}
          height={size}
          width={size}/>
        </span> 
        </Zoom>
      </div>
    )
}
